import React from 'react'
import PropTypes from 'prop-types'

import { Loading as LoadingSpinner } from '@termly/react-components'

import Styles from './styles.scss'


export default function Loading({ 'data-testid': dataTestID }) {
  return (
    <div
      className={ Styles.root }
      data-testid={ dataTestID }
    >
      <div className={ Styles.sizer }>
        <LoadingSpinner />
      </div>
    </div>
  )
}

Loading.propTypes = {
  'data-testid': PropTypes.string,
}
